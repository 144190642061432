import '../sass/app.scss';
import './sideBar';
import Flicking from "@egjs/flicking";
//import { Pagination } from "@egjs/flicking-plugins";

window.addEventListener('scroll', () => {
    if (window.pageYOffset > 100) {
        document.getElementById('mainNavigation').classList.add('scroll');
    } else {
        document.getElementById('mainNavigation').classList.remove('scroll');
    }
});

const newsSlide = document.getElementById("new-home");

if (newsSlide) {
    const flicking = new Flicking("#new-home", {
        align: "prev",
    });
}

