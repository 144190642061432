const openMenuBtn = document.getElementById("openMenuBtn");
const closeMenuBtn = document.getElementById("closeMenuBtn");

if (openMenuBtn) {
    openMenuBtn.addEventListener("click", () => {
        document.getElementById("panelNavigation").classList.add("open");
    });
}

if (closeMenuBtn) {
    closeMenuBtn.addEventListener("click", () => {
        document.getElementById("panelNavigation").classList.remove("open");
    });
}
